<template>
  <div class="my-coupon-page">
    <Backbar
      :title="$t('historyPage.title')"
      @onBackClick="$router.go(-1)"
    />
    <Switchbar
      :data="[$t('historyPage.couponUsed'), $t('historyPage.purchaseList')]"
      @onChangePage="onChangePage"
      :page="currentPage"
    />
    <div class="coupon-section has-padding-20 has-background-white">
      <div class="is-flex justify-space-between align-items-center">
        <div class="_fw-500 _fs-16 has-text-black is-flex align-items-center">
          <!-- filter -->
          <ToggleButton
            :name="currentPage === 0 ? 'Date added' : 'Purchase date'"
            :isActive="isSortDate"
            @onToggleClick="(active) => this.isSortDate = !active"
          />
        </div>
        <div>
          <img src="@/assets/icon/filter.svg"/>
        </div>
      </div>
      <div v-if="currentPage === 0">
        <div v-if="myCoupons && myCoupons.length > 0">
          <CouponItem
            v-for="(myCoupon, index) in sortCoupons"
            :key="index"
            :name="$i18n.locale === 'th' ? myCoupon.coupon.name : myCoupon.coupon.name_en"
            :coupon-id="myCoupon.coupon.code"
            :img-path="myCoupon.coupon.thumbnail_url"
            :id="myCoupon.id"
            :badge="getBadge(myCoupon)"
            text-button="Details"
            is-history
            is-show-coupon-id
            :used-time="myCoupon && myCoupon.used_time || 0"
            :limit-time="myCoupon && myCoupon.num_time || 0"
            :expired-date="myCoupon.expired_at"
            :last-used-date="lastUsedAt(myCoupon.coupon)"
            @onClickMoreDetails="$router.push({ name: 'CouponDetail', params: { id: myCoupon.id }, query: { mode: 'history' } })"
          />
        </div>
        <div class="has-text-centered _fs-12 has-margin-top-10" v-else>{{$t('common.noData')}}</div>
      </div>
      <div v-if="currentPage === 1">
        <div v-if="purchaseList && purchaseList.length > 0">
          <PurchasedItem
            v-for="(purchase, index) in sortPurchase"
            :key="index"
            :name="purchase.store_name"
            :balance="purchase.purchase_point"
            :last-used-date="purchase.purchase_date"
          />
        </div>
        <div class="has-text-centered _fs-12 has-margin-top-10" v-else>{{$t('common.noData')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import Backbar from '@/components/Backbar.vue'

import CouponItem from '@/components/coupon/CouponItem.vue'
import PurchasedItem from '@/components/coupon/PurchasedItem.vue'
import Switchbar from '@/components/Switchbar.vue'

import coupons from '@/mock/coupon'
import ToggleButton from '@/components/base/ToggleButton.vue'
export default {
  name: 'HistoryCoupon',
  components: {
    Backbar,
    CouponItem,
    PurchasedItem,
    Switchbar,
    ToggleButton
  },
  data () {
    return {
      coupons,
      currentPage: 0,
      isSortDate: false
    }
  },
  computed: {
    ...mapState('Coupon', ['myCoupons', 'purchaseList']),
    sortCoupons () {
      return [...this.myCoupons].sort((a, b) => this.isSortDate ? new Date(b.created_at) - new Date(a.created_at) : new Date(a.created_at) - new Date(b.created_at))
    },
    sortPurchase () {
      return [...this.purchaseList].sort((a, b) => this.isSortDate ? new Date(b.purchase_date) - new Date(a.purchase_date) : new Date(a.purchase_date) - new Date(b.purchase_date))
    }
  },
  methods: {
    ...mapActions({
      fetchMyCoupon: 'Coupon/fetchMyCoupon',
      fetchPurchaseList: 'Coupon/fetchPurchaseList'
    }),
    ...mapWaitingActions('Coupon', {
      fetchMyCoupon: 'app loading',
      fetchPurchaseList: 'app loading'
    }),
    async onChangePage (index) {
      if (index === 0) {
        this.$router.replace({
          name: 'HistoryCoupon',
          query: { mode: 'couponUsage' }
        })
      } else if (index === 1) {
        this.$router.replace({
          name: 'HistoryCoupon',
          query: { mode: 'purchasedList' }
        })
      }
    },
    getBadge (coupon) {
      const collectMethod = (coupon && coupon.collect_method) || ''
      if (collectMethod === 'exclusive') {
        return this.$t('common.level', { level: `${this.$i18n.locale === 'th' ? (coupon && coupon.member_card && coupon.member_card.name) || '' : (coupon && coupon.member_card && coupon.member_card.name_en) || ''}` })
      } else {
        return (coupon && coupon.collect_method) || ''
      }
    },
    lastUsedAt (coupon) {
      if (!coupon) {
        return null
      }
      const { transactions } = coupon
      if (!transactions) {
        return null
      }
      if (transactions.length <= 0) {
        return null
      }
      const lastTransaction = transactions[transactions.length - 1]
      return lastTransaction.used_at
    }
  },
  async mounted () {
    const mode = this.$route.query.mode || 'couponUsage'
    this.currentPage = mode === 'couponUsage' ? 0 : 1
    if (this.currentPage === 0) {
      await this.fetchMyCoupon()
    } else {
      await this.fetchPurchaseList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.switch-wrapper {
  position: relative;
  overflow-x: auto;
  min-height: 60px;
  .switch {
    min-height: 60px;
    color: white;
    position: relative;
    color: $black;
    min-width: 80px;
    padding-bottom: 4px;
  }
  .indicator {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    z-index: 2;
    height: 2px;
    background: $light-green;
    border-radius: 2px;
  }
}
.indicator-bar {
  width: 100%;
  z-index: 1;
  height: 2px;
  border-radius: 2px;
  background: $light-gray;
  margin-top: -2px;
}
</style>
