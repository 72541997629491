<template>
  <div class="purchased-item">
    <div class="is-flex align-items-center">
      <div class="coupon-img-wrapper">
        <img :src="imgPath"
          @error="replaceByDefault"
        />
      </div>
      <div class="has-margin-left-15 flex-1">
        <div class="is-flex justify-space-between">
          <div class="has-text-black _fs-16 _fw-500">{{name}}</div>
          <div class="_fs-14 _fw-500 has-text-primary">{{ balance | formatNumber }} {{$t('common.baht')}}</div>
        </div>
        <div class="_fs-10 has-text-black40">{{$t('historyPage.dateAt', { dateAt: lastUsedAt})}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    name: {
      type: String
    },
    imgPath: {
      type: String,
      default: ''
    },
    balance: {
      type: Number
    },
    lastUsedDate: {
      type: String
    }
  },
  computed: {
    lastUsedAt () {
      return moment(this.lastUsedDate).locale(this.$i18n.locale).format('DD MMM YYYY')
    }
  },
  methods: {
    replaceByDefault (e) {
      e.target.src = require('@/assets/img/coupon-default.png')
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.purchased-item {
  padding: 20px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  .coupon-img-wrapper {
    height: 56px;
    width: 56px;
    border-radius: 100%;
    background: $greyd8;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
    }
  }
}
</style>
